import React from "react";
import { StyleSheet, View } from "react-native";
import { Button, Image } from "@rneui/base";

import * as ExpoImagePicker from "expo-image-picker";
import Colors from "../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";

export default function ImagePicker({
  image,
  setImage,
}: {
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
}) {
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  return (
    <View style={styles.container}>
      <View>
        <Button
          buttonStyle={{ backgroundColor: "none", width: 250, height: 250 }}
          onPress={pickImage}
        >
          {image ? (
            <Image source={{ uri: image }} style={styles.thumbnail} />
          ) : (
            <View style={styles.button}>
              <FontAwesome name="camera" size={50} color="white" />
            </View>
          )}
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
  },
  button: {
    backgroundColor: Colors.default.pink,
    height: 100,
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 100,
  },
  thumbnail: { width: 250, height: 250 },
});
