import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useDirectus } from "./useDirectus";

enum ArticleStatus {
  ARCHIVED = "archived",
  PUBLISHED = "published",
  DRAFT = "draft",
}

function useGetPosts() {
  const { getDirectus } = useDirectus();
  const directus = getDirectus();
  const { currentUser } = useContext(AuthContext);

  return useQuery(["posts"], () =>
    directus.items<"wop_articles", Article>("wop_articles").readByQuery({
      filter: {
        status: { _neq: ArticleStatus.ARCHIVED },
        user_created: { _eq: currentUser?.id },
      },
    })
  );
}

export default useGetPosts;
