import { ManyItems } from "@directus/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDirectus } from "./useDirectus";

enum ArticleStatus {
  ARCHIVED = "archived",
  PUBLISHED = "published",
  DRAFT = "draft",
}

function useArchivePost() {
  const queryClient = useQueryClient();

  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  return useMutation({
    mutationFn: (articleId: string) => {
      return directus
        .items<"wop_articles", Article>("wop_articles")
        .updateOne(articleId, { status: ArticleStatus.ARCHIVED });
    },
    onMutate: async (articleId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["posts"],
      });

      // Snapshot the previous value
      let previousPosts = queryClient.getQueryData<ManyItems<Article>>([
        "posts",
      ]);

      // Optimistically update to the new value
      if (previousPosts?.data) {
        queryClient.setQueryData(
          ["posts"],
          previousPosts.data.filter((p) => {
            p.id !== articleId;
          })
        );
      }

      previousPosts = queryClient.getQueryData(["posts"]);

      return { previousPosts };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["posts"], context?.previousPosts);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });
}

export default useArchivePost;
