import { useQuery } from "@tanstack/react-query";
import { useDirectus } from "./useDirectus";

enum ReservationStatus {
  ARCHIVED = "archived",
  PUBLISHED = "published",
  DRAFT = "draft",
}

function useGetReservations() {
  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  return useQuery(["reservations"], () =>
    directus
      .items<"wop_reservations", Reservation>("wop_reservations")
      .readByQuery({
        filter: { status: { _neq: ReservationStatus.ARCHIVED } },
      })
  );
}

export default useGetReservations;
