import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ThemeProvider, createTheme } from "@rneui/themed";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

import { DIRECTUS_URL } from "@env";

import useCachedResources from "./hooks/useCachedResources";
import { DirectusProvider } from "./hooks/useDirectus";
import Navigation from "./navigation";

const theme = createTheme({
  Card: {
    containerStyle: {
      width: "100%",
      maxWidth: 343,
      alignSelf: "center",
      borderRadius: 30,
      padding: 0,
      marginTop: 0,
      marginBottom: 50,
      marginHorizontal: 0,
      borderWidth: 0,
      shadowOpacity: 0.25,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 4 },
      shadowRadius: 4,
    },
    wrapperStyle: {
      padding: 0,
      margin: 0,
    },
  },
});

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    if (!DIRECTUS_URL) throw new Error("DIRECTUS_URL is not set");

    return (
      <ThemeProvider theme={theme}>
        <SafeAreaProvider>
          <DirectusProvider apiUrl={DIRECTUS_URL}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={true} />
              <Navigation />
            </QueryClientProvider>
          </DirectusProvider>
          <StatusBar />
        </SafeAreaProvider>
      </ThemeProvider>
    );
  }
}
