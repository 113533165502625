import { UserItem } from "@directus/sdk";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import Colors from "../constants/Colors";
import AuthContext from "../contexts/AuthContext";
import { useDirectus } from "../hooks/useDirectus";

import HomeScreen from "../screens/HomeScreen";
import ModalScreen from "../screens/ModalScreen";
import PasswordResetScreen from "../screens/PasswordResetSreen";
import SignInScreen from "../screens/SignInScreen";
import SplashScreen from "../screens/SplashScreen";
import UploadScreen from "../screens/UploadScreen";
import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration} theme={DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

interface AuthAction {
  type: string;
  token: string | null;
}

interface AuthState {
  userToken: string | null;
  isLoading: boolean;
  isSignout: boolean;
}

function authReducer(prevState: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false,
      };
    case "SIGN_IN":
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      };
    default:
      return prevState;
  }
}

function RootNavigator() {
  const [state, dispatch] = React.useReducer(authReducer, {
    isLoading: true,
    isSignout: false,
    userToken: null,
  });

  const [currentUser, setCurrentUser] = React.useState(null);

  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      let token: string | null = null;

      try {
        const { data: user } = await directus.users.me.read();
        setCurrentUser(user);

        token = directus.auth.token;
      } catch (e) {
        console.error("Restoring token failed");
      }
      dispatch({ type: "RESTORE_TOKEN", token });
    };

    bootstrapAsync();
  }, [dispatch]);

  const authContext = React.useMemo(
    () => ({
      signIn: async ({
        email,
        password,
      }: {
        email: string;
        password: string;
      }) => {
        const { access_token } = await directus.auth.login({
          email,
          password,
        });

        await AsyncStorage.setItem("auth_token", access_token);

        dispatch({ type: "SIGN_IN", token: access_token });
      },
      signOut: () => dispatch({ type: "SIGN_OUT", token: null }),
    }),
    [dispatch, directus]
  );

  return (
    <AuthContext.Provider value={{ ...authContext, currentUser: currentUser }}>
      <Stack.Navigator>
        {state.isLoading ? (
          // We haven't finished checking for the token yet
          <Stack.Screen
            options={{ headerShown: false }}
            name="SplashScreen"
            component={SplashScreen}
          />
        ) : state.userToken == null ? (
          // No token found, user isn't signed in
          <>
            <Stack.Screen
              name="SignInScreen"
              component={SignInScreen}
              options={{
                // When logging out, a pop animation feels intuitive
                animationTypeForReplace: state.isSignout ? "pop" : "push",
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="PasswordResetScreen"
              component={PasswordResetScreen}
              options={{
                // When logging out, a pop animation feels intuitive
                animationTypeForReplace: state.isSignout ? "pop" : "push",
                headerShown: true,
                headerStyle: { backgroundColor: Colors.default.background },
                headerTitle: "",
                headerShadowVisible: false,
              }}
            />
          </>
        ) : (
          // User is signed in
          <>
            <Stack.Screen
              name="HomeScreen"
              component={HomeScreen}
              options={{ headerShown: false }}
            />
            <Stack.Group
              screenOptions={{
                presentation: "modal",
                headerShown: true,
                headerBackVisible: true,
                headerLargeTitle: false,
                headerLargeStyle: {
                  backgroundColor: Colors.default.background,
                },
              }}
            >
              <Stack.Screen name="Modal" component={ModalScreen} />
            </Stack.Group>
            <Stack.Screen
              name="UploadScreen"
              component={UploadScreen}
              options={{
                headerStyle: { backgroundColor: Colors.default.background },
                headerTitle: "",
                headerShadowVisible: false,
              }}
            />
          </>
        )}
      </Stack.Navigator>
    </AuthContext.Provider>
  );
}
