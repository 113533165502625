import { ManyItems } from "@directus/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uniqueId } from "lodash";
import { useDirectus } from "./useDirectus";

enum ReservationStatus {
  ARCHIVED = "archived",
  PUBLISHED = "published",
  DRAFT = "draft",
}

function useReserveArticle() {
  const queryClient = useQueryClient();

  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  return useMutation({
    mutationFn: (articleId: string) =>
      directus
        .items("wop_reservations")
        .createOne({ article: articleId, status: "published" }),
    onMutate: async (articleId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["reservations"],
      });

      // Snapshot the previous value
      let previousReservations = queryClient.getQueryData<
        ManyItems<Reservation>
      >(["reservations"]);

      // Optimistically update to the new value
      if (previousReservations?.data) {
        queryClient.setQueryData(
          ["reservations"],
          [
            ...previousReservations.data,
            {
              article: articleId,
              status: ReservationStatus.PUBLISHED,
              id: uniqueId(),
            },
          ]
        );
      }

      previousReservations = queryClient.getQueryData(["reservations"]);

      return { previousReservations };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["posts"], context?.previousReservations);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["reservations"] });
    },
  });
}

export default useReserveArticle;
