import { UserItem } from "@directus/sdk";
import React from "react";

interface AuthContext {
  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<void>;
  signOut: () => void;
  currentUser: UserItem<{ id: string }>;
}

export default React.createContext<AuthContext>({
  signIn: async () => {
    throw new Error("Context not initialized");
  },
  signOut: () => undefined,
  currentUser: { email: "", id: "" },
});
