import { Button, ButtonProps } from "@rneui/base";
import React from "react";
import { View, StyleSheet } from "react-native";
import Colors from "../constants/Colors";

const StyledButton = ({
  title,
  onPress,
  secondary,
}: {
  secondary?: boolean;
} & ButtonProps) => (
  <View style={styles.buttonContainer}>
    <Button
      buttonStyle={secondary ? styles.buttonSecondary : styles.button}
      titleStyle={secondary ? styles.buttonSecondaryTitle : styles.buttonTitle}
      title={title}
      onPress={onPress}
    />
  </View>
);

const styles = StyleSheet.create({
  button: {
    backgroundColor: Colors.default.text,
    borderRadius: 15,
    height: 50,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  buttonTitle: {
    fontSize: 18,
    color: Colors.default.white,
    fontWeight: "700",
  },
  buttonContainer: {
    paddingHorizontal: "20%",
    paddingVertical: 10,
  },
  buttonSecondary: {
    backgroundColor: Colors.default.background,
    height: 50,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  buttonSecondaryTitle: {
    fontSize: 18,
    color: Colors.default.text,
    fontWeight: "700",
  },
});

export default StyledButton;
