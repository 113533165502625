export default [
  { label: 'Bekleidung&Schuhe', value: 'Bekleidung&Schuhe' },
  { label: 'Küche&Lebensmittel', value: 'Küche&Lebensmittel' },
  { label: 'Kosmetik', value: 'Kosmetik' },
  { label: 'Möbel&Deko', value: 'Möbel&Deko' },
  { label: 'Pflanzen&Garten', value: 'Pflanzen&Garten' },
  { label: 'Kindersachen', value: 'Kindersachen' },
  { label: 'Bücher&Literatur', value: 'Bücher&Literatur' },
  { label: 'Kunst&Musik', value: 'Kunst&Musik' },
  { label: 'Elektronisches', value: 'Elektronisches' },
  { label: 'Fahrzeuge', value: 'Fahrzeuge' },
  { label: 'Hobby&Sport', value: 'Hobby&Sport' },
  { label: 'Tiersachen', value: 'Tiersachen' },
  { label: 'Anderes', value: 'Anderes' },
]