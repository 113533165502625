import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { useDirectus } from "../hooks/useDirectus";
import ProductCard from "./GenericCard";
import { PartialItem } from "@directus/sdk";
import Colors from "../constants/Colors";
import useReserveArticle from "../hooks/useReserveArticle";
import useCurrentUser from "../hooks/useCurrentUser";

interface Props {
  articles: PartialItem<Article>[];
  reservations: PartialItem<Reservation>[];
  handleDismissAvailableItem: (id: string) => void;
}

const AvailableList: React.FunctionComponent<Props> = ({
  articles,
  reservations,
  handleDismissAvailableItem,
}) => {
  const { data: currentUser } = useCurrentUser();
  const { mutate: reserveArticle } = useReserveArticle();

  const userHasReservations = reservations.some((r) => {
    return r.user_created === currentUser?.id && r.status === "published";
  });

  const handleReserveAvailableItem = async (article: PartialItem<Article>) => {
    if (!article || !article.id) throw new Error("Missing item ID");
    const itemHasPublishedReservations =
      article.reservations &&
      article.reservations?.some((r) => {
        const reservation = reservations.find((r) => r.article == article.id);
        return reservation?.status === "published";
      });

    if (itemHasPublishedReservations || userHasReservations) {
      return;
    }

    reserveArticle(article.id);
  };

  return (
    <View>
      {!!articles.length && (
        <Text style={styles.title}>Verfügbare Produkte</Text>
      )}
      {articles.map((article) => (
        <ProductCard
          key={article.id}
          buttonRight={{
            icon: "check",
            disabled: userHasReservations,
            onPress: () => handleReserveAvailableItem(article),
            color: "#84E0DA",
          }}
          buttonLeft={{
            icon: "close",
            onPress: () => handleDismissAvailableItem(article.id),
            color: "#D688D8",
          }}
          {...article}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontSize: 24,
    color: Colors.default.text,
    marginBottom: 24,
  },
});

export default AvailableList;
