import { View, StyleSheet } from "react-native";
import { Card, Text, Icon } from "@rneui/themed";
import { FunctionComponent } from "react";
import Colors from "../constants/Colors";

interface Props extends Partial<Article> {
  buttonRight: {
    icon: string;
    onPress: () => void;
    color: string;
    disabled?: boolean;
  };
  buttonLeft: {
    icon: string;
    onPress: () => void;
    color: string;
    disabled?: boolean;
  };
  modal: FunctionComponent<any>;
}

function CardContainer({ children, modal }) {
  const Modal = modal;

  return (
    <View>
      <Card style={{ position: "relative" }}>
        {modal && <Modal />}
        {children}
      </Card>
    </View>
  );
}

export function ImageCard({ image, title, buttons, text, modal }) {
  return (
    <CardContainer modal={modal}>
      <Card.Image
        style={styles.image}
        source={{
          uri: `assets/${image}`,
        }}
      />
      <View style={styles.container}>
        <Card.Title style={styles.title}>{title}</Card.Title>
        <Text>{text}</Text>
        <View style={styles.buttonWrapper}>{buttons}</View>
      </View>
    </CardContainer>
  );
}

function ProductCard({
  title,
  image,
  description,
  category,
  buttonRight,
  buttonLeft,
  modal,
}: Props) {
  return (
    <ImageCard
      modal={modal}
      title={title}
      image={image}
      buttons={
        <>
          <Icon
            name={buttonLeft.icon}
            color={buttonLeft.color}
            containerStyle={
              buttonLeft.disabled
                ? { ...styles.button, ...styles.disabledContainer }
                : styles.button
            }
            reverse
            onPress={buttonLeft.onPress}
            disabled={buttonLeft.disabled}
          />
          <Icon
            name={buttonRight.icon}
            color={buttonRight.color}
            disabledStyle={styles.disabledButton}
            disabled={buttonRight.disabled}
            containerStyle={
              buttonRight.disabled
                ? { ...styles.button, ...styles.disabledContainer }
                : styles.button
            }
            onPress={buttonRight.onPress}
            reverse
          />
        </>
      }
      text={
        <>
          <Text style={styles.category}>{category && `${category}`} </Text>
          <Text style={styles.description}>{description}</Text>
        </>
      }
    ></ImageCard>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    padding: 16,
  },
  title: {
    fontSize: 24,
    color: Colors.default.text,
    textAlign: "left",
    marginTop: 0,
    marginBottom: 4,
  },
  image: {
    height: 300,
    resizeMode: "cover",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  description: {
    fontSize: 18,
    lineHeight: 26,
  },
  category: {
    display: "flex",
    fontSize: 14,
    marginBottom: 4,
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
  },
  disabledContainer: {
    shadowRadius: 0,
    shadowOpacity: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
  button: {
    opacity: 1,
    shadowOffset: {
      width: 0,
      height: 20,
    },
    shadowRadius: 25,
    shadowOpacity: 0.15,
  },
});

export default ProductCard;
