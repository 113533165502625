import React, { useReducer, useState } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  ActivityIndicator,
} from "react-native";
import { FAB } from "@rneui/themed";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import CurrentUserReservations from "../components/CurrentUserReservationList";
import WopLogo from "../components/WopLogo";
import Colors from "../constants/Colors";
import useGetReservations from "../hooks/useGetReservations";
import PostedProducts from "../components/PostedProducts";
import useGetPosts from "../hooks/useGetPosts";
import useGetAvailableArticles from "../hooks/useGetAvailableItems";
import AvailableList from "../components/AvailableList";
import useCurrentUser from "../hooks/useCurrentUser";

const Fallback = ({ visible }: { visible: boolean }) => {
  if (!visible) return null;

  return (
    <View>
      <WopLogo scale={0.3} />
      <Text style={styles.noProducts}>
        Es gibt keine verfügbaren Produkte in deiner Umgebung
      </Text>
    </View>
  );
};

export default function HomeScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "HomeScreen">) {
  function handleUploadPress() {
    navigation.push("UploadScreen");
  }

  const { data: reservations } = useGetReservations();
  const { data: posts } = useGetPosts();
  const { data: articles } = useGetAvailableArticles();

  const [isLoading] = useState(false);
  const [hiddenArticles, hideArticle] = useReducer(
    (filteredIds: string[], newId: string) => [...filteredIds, newId],
    []
  );

  if (isLoading) {
    return (
      <ScrollView>
        <View style={styles.container}>
          <ActivityIndicator color={"#fff"} size="large" />
        </View>
      </ScrollView>
    );
  }

  // if (entities.currentUser == null) throw new Error("Not logged in");

  const showFallback = false;
  // selectUserPosts(entities.currentUser?.id, entities.articles).length +
  //   selectAvailableArticles(entities.articles, entities.hiddenArticles)
  //     .length +
  //   selectUserReservations(entities.currentUser?.id, entities.reservations)
  //     .length ===
  // 0;

  // FIXME: types can be probably simplified/improved
  type WithId = unknown & { id: string };
  const notIn =
    (blacklist: string[]) =>
    (c: unknown & { id?: string }): c is WithId => {
      if (!c?.id) return false;
      return !blacklist.includes(c.id);
    };

  return (
    <View style={styles.flexContainer}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollContainer}
      >
        <View style={styles.container}>
          {/* <EntitiesContext.Provider value={entities}> */}
          {/* <EntitiesContext.Provider value={{}}> */}
          {showFallback ? (
            <Fallback visible={true} />
          ) : (
            <>
              <CurrentUserReservations
                reservations={reservations?.data ?? []}
              />
              <AvailableList
                articles={articles?.data?.filter(notIn(hiddenArticles)) ?? []}
                handleDismissAvailableItem={hideArticle}
                reservations={reservations?.data ?? []}
              />
              <PostedProducts posts={posts?.data ?? []} />
            </>
          )}
          {/* </EntitiesContext.Provider> */}
        </View>
      </ScrollView>
      <FAB
        color="#D688D8"
        icon={{ name: "add", color: "white" }}
        onPress={handleUploadPress}
        placement="right"
        size="large"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    alignItems: "stretch",
    height: "100%",
  },
  container: {
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingTop: 50,
    paddingBottom: 0,
    backgroundColor: Colors.default.background,
    minHeight: "100%",
  },
  flexContainer: {
    flex: 1,
  },
  noProducts: {
    textAlign: "center",
    fontSize: 24,
    color: Colors.default.text,
    marginBottom: 24,
  },
});
