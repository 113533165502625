import { Icon } from "@rneui/themed";
import { PropsWithChildren } from "react";
import { View, StyleSheet } from "react-native";

export default function ModalScreen({
  children,
  setModalIsVisible,
}: PropsWithChildren<{
  setModalIsVisible: (nextState: boolean) => void;
}>) {
  return (
    <View style={styles.modal}>
      <Icon
        name="close"
        color="white"
        style={styles.closeButton}
        onPress={() => setModalIsVisible(false)}
      />
      <View>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: "absolute",
    backgroundColor: "#D688D8",
    height: "100%",
    width: "100%",
    padding: 16,
    zIndex: 1,
    borderRadius: 30,
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  closeButton: {
    bottom: 0,
    right: 0,
    marginRight: 10,
    marginTop: 10,
  },
});
