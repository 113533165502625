import {
  Directus,
  IDirectus,
  BaseStorage,
  StorageOptions,
} from "@directus/sdk";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
class NativeStorage extends BaseStorage {
  storage: Record<string, string> = {};

  constructor(options?: StorageOptions) {
    super(options);
    AsyncStorage.getAllKeys()
      .then(async (keys) =>
        AsyncStorage.multiGet(keys).then((entries) => {
          this.storage = entries.reduce(
            (storage, [key, value]) => ({
              [key]: value,
              ...storage,
            }),
            {}
          );
        })
      )
      .catch(console.error);
  }

  get(key: string) {
    return this.storage[key];
  }
  set(key: string, value: string) {
    this.storage[key] = value;
    return this.storage[key];
  }
  delete(key: string) {
    const value = this.storage[key];

    delete this.storage[key];
    return value ?? null;
  }
}

interface DirectusContext {
  getDirectus: () => IDirectus<{
    articles: Article;
    reservations: Reservation;
  }>;
}

const directusContext = createContext<DirectusContext>({
  getDirectus: () => {
    throw new Error("Directus not initialized");
  },
});

export function DirectusProvider({
  apiUrl,
  children,
}: PropsWithChildren<{
  apiUrl: string;
}>) {
  const value = useMemo<DirectusContext>(
    () => ({
      getDirectus: () =>
        new Directus(apiUrl, {
          storage: new NativeStorage(),
          auth: {
            mode: "cookie",
            autoRefresh: true,
            msRefreshBeforeExpires: 1000,
          },
        }),
    }),
    [apiUrl]
  );

  return (
    <directusContext.Provider value={value}>
      {children}
    </directusContext.Provider>
  );
}

export function useDirectus() {
  const context = useContext(directusContext);
  return context;
}
