import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { PartialItem } from "@directus/sdk";
import ReservationCard from "./ReservationCard";
import Colors from "../constants/Colors";

interface Props {
  reservations: Array<PartialItem<Reservation>>;
}

const CurrentUserReservations: React.FunctionComponent<Props> = ({
  reservations,
}) => {
  return (
    <View>
      {!!reservations.length && (
        <Text style={styles.title}>Deine Reservationen</Text>
      )}
      {reservations.map((r) => (
        <ReservationCard key={r.id} reservation={r} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontSize: 24,
    color: Colors.default.text,
    marginBottom: 24,
  },
});

export default CurrentUserReservations;
