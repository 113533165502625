import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-bbox="0 0 1065.2 1065.2"
    viewBox="0 0 1065.2 1065.2"
    data-type="color"
    aria-hidden="true"
    aria-labelledby="svgcid-37v3yw5ob8do"
    {...props}
  >
    <Path
      fill="#ff0"
      d="M1065.2 532.6c0 294.147-238.453 532.6-532.6 532.6C238.453 1065.2 0 826.747 0 532.6 0 238.453 238.453 0 532.6 0c294.147 0 532.6 238.453 532.6 532.6z"
      data-color={1}
    />
    <Path
      d="M369.9 433.4h-29.8l-28.8-103.5-29 103.5h-29.7l-40.9-156.5h33.8l23 99.2 27.6-99.2h30.3l27.7 99.4 23-99.4h34l-41.2 156.5zM745 434c-24 0-43.1-8.1-57-24.1l-.1-.2c-12.2-15.2-18.3-34.5-18.3-57.5 0-20.7 5.3-38.7 15.6-53.6 13.6-19.5 33.9-29.3 60.2-29.3 27.4 0 48.1 9.1 61.5 27 10.3 13.8 15.6 31.5 15.6 52.7 0 22.8-5.9 42.2-17.7 57.5-14 18.2-34.1 27.5-59.8 27.5zm1.6-135.3c-13.3 0-23.9 4.4-32.2 13.5-8.4 9.1-12.6 23-12.6 41.4 0 14.6 3.7 27.1 11 37 7 9.5 18.3 14.1 34.6 14.1 15.2 0 25.8-5.1 32.3-15.7 7.1-11.4 10.7-24.6 10.7-39.5 0-15.5-4-28-11.9-37.2-7.8-9.2-18.2-13.6-31.9-13.6zM535.6 904.4c-69 0-129.6-16.8-180.3-50-40.6-26.6-74.7-63.5-101.3-109.7-45-78-53.2-155-53.6-158.2l30.8-3.1c.1.7 8.1 74.4 50.1 146.7 24.3 41.8 55 75 91.5 98.7 45.5 29.6 100.3 44.7 162.9 44.7 57.1 0 106.7-15.1 147.2-44.9 32.7-24 59.9-57.6 80.9-100 36.5-73.5 41.7-148.6 41.7-149.3l1-15.7 40.5 3.1c4.4.2 32.3 1.5 45.1 4.4 11.9 2.7 27 7.3 37.2 18.9 8.7 9.8 13 22.5 12.9 38-.1 14.6-1 22.4-6.7 33.4-7.1 13.5-16.6 23.1-40.5 26.4-19.4 2.6-52.8 1.2-54.2 1.1h-.3l-26.4-1.6c-5.8 17.4-13.1 35.9-22.2 54.4-23.2 47.1-53.6 84.7-90.3 111.8-46.1 33.7-101.9 50.9-166 50.9zm306.7-246.7c2.2.1 32.4 1.4 48.5-.8 12.9-1.8 14.5-4.9 17.2-10 2.4-4.6 3.1-6.6 3.2-19.1 0-10.2-2.9-14.8-5.1-17.3-3.4-3.8-10.2-6.8-20.9-9.3-7.8-1.8-28.5-3.2-39.7-3.6h-.5l-10.1-.8c-1.7 13.5-5.1 34.6-11.6 59.8l19 1.1z"
      data-color={2}
    />
  </Svg>
);

export default SvgComponent;
