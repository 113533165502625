import React, { useState } from "react";
import { PartialItem } from "@directus/sdk";
import { ImageCard } from "./GenericCard";
import { StyleSheet } from "react-native";
import { Icon } from "@rneui/themed";
import Colors from "../constants/Colors";
import ContactModal from "../screens/ContactModal";
import useArchiveReservation from "../hooks/useArchiveReservation";

interface Props {
  reservation: PartialItem<Article & { reservation: Partial<Reservation> }>;
}

type ProfileData = Partial<ValidProfile>;

interface ValidProfile {
  email: string;
  user_id: string;
  display_name: string;
}

const isValidProfile = (
  data: Partial<ProfileData>[] | undefined | null
): data is [ValidProfile] => {
  if (
    !data ||
    data.length !== 1 ||
    data.some((p) => !p.display_name && !p.email && !p.user_id)
  )
    return false;
  return true;
};

const ReservationCard: React.FunctionComponent<Props> = ({ reservation }) => {
  const { mutate } = useArchiveReservation();

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    displayName: "",
  });

  const handleShowContactDetails = async (userId: string) => {
    // const { data } = await directus
    //   .items<"wop_profiles", ProfileData>("wop_profiles")
    //   .readByQuery({ filter: { user_id: userId } });
    // if (!isValidProfile(data)) {
    //   throw new Error("Couldn't retrieve profile");
    // }
    // const { display_name, email } = data[0];
    // setUserDetails({ displayName: display_name, email });
  };

  return (
    <ImageCard
      modal={() =>
        modalIsVisible && (
          <ContactModal
            userDetails={{
              displayName: userDetails.displayName,
              email: userDetails.email,
            }}
            setModalIsVisible={setModalIsVisible}
          />
        )
      }
      key={reservation.id}
      image={reservation.article.image}
      title={"Reserviert für dich"}
      text={"Du hast bis 07.12"}
      buttons={
        <>
          <Icon
            name="close"
            onPress={() => {
              mutate(reservation.id);
            }}
            color="#D688D8"
            reverse
            containerStyle={styles.button}
          />
          <Icon
            name="mail"
            color="#84E0DA"
            onPress={() => {
              handleShowContactDetails(reservation.user_created ?? "");
              setModalIsVisible(true);
            }}
            reverse
            containerStyle={styles.button}
          />
        </>
      }
    />
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    padding: 16,
  },
  title: {
    fontSize: 24,
    color: Colors.default.text,
    textAlign: "left",
    marginTop: 0,
    marginBottom: 4,
  },
  image: {
    height: 300,
    resizeMode: "cover",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  description: {
    fontSize: 18,
    lineHeight: 26,
  },
  category: {
    display: "flex",
    fontSize: 14,
    marginBottom: 4,
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
  },
  disabledContainer: {
    shadowRadius: 0,
    shadowOpacity: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
  button: {
    opacity: 1,
    shadowOffset: {
      width: 0,
      height: 20,
    },
    shadowRadius: 25,
    shadowOpacity: 0.15,
  },
});

export default ReservationCard;
