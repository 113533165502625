import { useMutation } from "@tanstack/react-query";
import { useDirectus } from "./useDirectus";

function useUploadFile() {
  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  return useMutation({
    mutationFn: async (formData: FormData) =>
      directus.files
        .createOne(
          formData,
          {},
          {
            requestOptions: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          }
        )
        .then((r) => ({ imageId: r.id })),
  });
}

export default useUploadFile;
