import { PartialItem } from "@directus/sdk";
import React from "react";
import { StyleSheet, Text } from "react-native";
import ProductCard from "./GenericCard";
import Colors from "../constants/Colors";
import useArchivePost from "../hooks/useArchivePost";

function PostedProducts({ posts }: { posts: PartialItem<Article>[] }) {
  if (!posts.length) return null;

  const { mutate: archivePost } = useArchivePost();

  const filterIDs = function (list: Array<unknown> | undefined) {
    if (!list) return [];
    return list.filter(
      (c): c is string | number =>
        !!c && (typeof c === "string" || typeof c === "number")
    );
  };

  const handleDeletePost = async (article: PartialItem<Article>) => {
    if (!article || !article.id) throw new Error("Missing item ID");
    // const { reservations } = article;

    // // TODO: this should be done by a custom controller/resolver, to keep the rights at a minimum.
    // try {
    //   await directus
    //     .items("wop_reservations")
    //     .updateMany(filterIDs(reservations), {
    //       // more statuses could be added if need be ("deleted", "expired", "failed")
    //       status: "archived",
    //     });
    // } catch (e) {
    //   throw new Error(e);
    // }

    const item_id = article.id;
    archivePost(item_id);
  };
  // TODO: Without multiple "archived" states delete and close are identical
  const handleClosePost = handleDeletePost;

  return (
    <>
      {!!posts.length && <Text style={styles.title}>Meine Produkte</Text>}
      {posts.map((post) => {
        return (
          <ProductCard
            key={post.id}
            buttonRight={{
              icon: "check",
              color: "#84E0DA",
              onPress: function (): void {
                handleClosePost(post);
              },
            }}
            buttonLeft={{
              icon: "delete",
              onPress: function (): void {
                handleDeletePost(post);
              },
              color: "#D688D8",
            }}
            {...post}
            description="Hat der Austausch funktioniert oder möchtest du es löschen?"
          />
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontSize: 24,
    color: Colors.default.text,
    marginBottom: 24,
  },
});

export default PostedProducts;
