import { ManyItems, PartialItem } from "@directus/sdk";
import { uniqueId } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDirectus } from "./useDirectus";

interface ArticleCreate {
  title: string;
  description: string;
  category: string;
  image: string;
}

function usePostArticle() {
  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (article: ArticleCreate) => {
      console.log(article);

      return directus
        .items<"wop_articles", Article>("wop_articles")
        .createOne(article);
    },
    onMutate: async (newPost: ArticleCreate) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["posts"],
      });

      // Snapshot the previous value
      let previousPosts: ManyItems<PartialItem<Article>> | undefined =
        queryClient.getQueryData(["posts"]);

      // Optimistically update to the new value
      if (previousPosts?.data) {
        queryClient.setQueryData(
          ["posts"],
          [...previousPosts.data, { ...newPost, id: uniqueId("temp_art_id_") }]
        );
      }

      previousPosts = queryClient.getQueryData(["posts"]);

      return { previousPosts };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["posts"], context?.previousPosts);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });
}

export default usePostArticle;
