import { Input, InputProps } from "@rneui/base";
import { StyleSheet } from "react-native";
import Colors from "../constants/Colors";

const StyledInput = ({
  onChangeText,
  onBlur,
  value,
  ...inputProps
}: Omit<InputProps, "ref">) => {
  return (
    <Input
      inputStyle={styles.textInput}
      inputContainerStyle={styles.textInputContainer}
      errorStyle={styles.textInputError}
      onBlur={onBlur}
      onChangeText={onChangeText}
      value={value}
      labelStyle={styles.inputLabel}
      {...inputProps}
    />
  );
};

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: Colors.default.white,
    borderRadius: 15,
    height: 50,
    marginBottom: 12,
    padding: 15,
  },
  textInputError: {},
  inputLabel: {
    fontSize: 18,
    color: Colors.default.text,
    fontWeight: "700",
    marginBottom: 10,
  },
  textInputContainer: {
    borderBottomWidth: 0,
    borderWidth: 0,
    margin: 0,
  },
});

export default StyledInput;
