import { useForm, Controller } from "react-hook-form";

import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import AuthContext from "../contexts/AuthContext";
import { Text } from "@rneui/base";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import WopLogo from "../components/WopLogo";

import Colors from "../constants/Colors";
import StyledButton from "../components/StyledButton";
import StyledInput from "../components/StyledInput";

export default function SignInScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "SignInScreen">) {
  const [globalError, setGlobalError] = useState("");

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { signIn } = useContext(AuthContext);

  function toPasswordReset() {
    navigation.push("PasswordResetScreen");
  }

  async function onSignIn(credentials: { email: string; password: string }) {
    try {
      await signIn(credentials);
    } catch (error) {
      let newError = "Server Error";
      if ((error as Error)?.message) {
        newError = (error as Error)?.message;
      }
      setGlobalError(newError);
    }
  }

  return (
    <View style={styles.container}>
      <WopLogo scale={0.5} />
      <Text>{globalError}</Text>
      <Controller
        control={form.control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <StyledInput
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              label="E-Mail"
              autoCapitalize="none"
              autoCompleteType="email"
            />
          </>
        )}
        name="email"
      />

      <Controller
        control={form.control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <StyledInput
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label="Password"
            secureTextEntry
            autoCapitalize="none"
            autoCompleteType="password"
          />
        )}
        name="password"
      />
      <StyledButton title="Sign in" onPress={form.handleSubmit(onSignIn)} />
      <StyledButton
        title="Reset password"
        secondary
        onPress={toPasswordReset}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.default.background,
    padding: 30,
    flex: 1,
  },
});
