import { OneItem, PartialItem } from "@directus/sdk";
import { useQuery } from "@tanstack/react-query";
import { useDirectus } from "./useDirectus";

function useCurrentUser() {
  const { getDirectus } = useDirectus();
  const directus = getDirectus();

  return useQuery(["current_user"], () => {
    return { id: "626a2349-919d-4a12-ad3f-d982ab130a3a" };
  });
}

export default useCurrentUser;
