import { useForm, Controller } from "react-hook-form";

import { View, Text, StyleSheet } from "react-native";
import React, { useState, useReducer } from "react";

import { useDirectus } from "../hooks/useDirectus";
import StyledInput from "../components/StyledInput";
import StyledButton from "../components/StyledButton";
import Colors from "../constants/Colors";

export default function PasswordResetScreen() {
  const [globalError, setGlobalError] = useState("");
  const { getDirectus } = useDirectus();
  const [done, setDone] = useReducer(() => true, false);

  const form = useForm({
    defaultValues: {
      email: "",
    },
  });

  async function onPasswordReset({ email }: { email: string }) {
    try {
      const directus = getDirectus();
      await directus.auth.password.request(email);
      setDone();
      form.reset();
    } catch (error) {
      setGlobalError((error as Error).message);
    }
  }

  return (
    <View style={styles.container}>
      <Text>{globalError}</Text>
      <Controller
        control={form.control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <StyledInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            autoCapitalize="none"
            autoCompleteType="email"
            label="E-Mail"
          />
        )}
        name="email"
      />
      {form.formState.errors.email && <Text>This is required.</Text>}

      <StyledButton
        title="Reset password"
        onPress={form.handleSubmit(onPasswordReset)}
      />

      <Text>
        {done &&
          "If the mail exists in our System you we will send the recovery link to this address."}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.default.background,
    padding: 30,
    flex: 1,
  },
});
