import { Card } from "@rneui/base";
import { Icon, Text } from "@rneui/themed";
import { View, StyleSheet, Linking } from "react-native";
import ModalScreen from "./ModalScreen";
export default function ContactModal({
  userDetails,
  setModalIsVisible,
}: {
  userDetails: Partial<{
    email: string;
    displayName: string;
  }>;
  setModalIsVisible: (nextState: boolean) => void;
}) {
  return (
    <ModalScreen setModalIsVisible={setModalIsVisible}>
      <Card.Title style={styles.title}>
        <Text>Schreibe ein Mail an {userDetails.displayName}</Text>
      </Card.Title>

      <View style={styles.buttonWrapper}>
        <Icon
          name="email"
          size={40}
          color="white"
          reverse
          reverseColor="#D688D8"
          onPress={() => {
            Linking.openURL(
              `mailto:${userDetails.email}?subject=WOP - Können wir treffen?`
            );
          }}
        />
      </View>
    </ModalScreen>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: "absolute",
    backgroundColor: "#D688D8",
    height: "100%",
    width: "100%",
    padding: 16,
    zIndex: 1,
    borderRadius: 30,
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 30,
  },
  title: {
    marginTop: 60,
    fontSize: 24,
    color: "white",
    textAlign: "center",
    marginVertical: 10,
  },
});
