import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { Controller, useForm } from "react-hook-form";
import DropDownPicker from "react-native-dropdown-picker";

import ImagePicker from "../components/ImagePicker";
import { RootStackParamList } from "../types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import CategoriesList from "../constants/CategoriesList";
import Colors from "../constants/Colors";
import StyledInput from "../components/StyledInput";
import StyledButton from "../components/StyledButton";
import usePostArticle from "../hooks/usePostArticle";
import useUploadFile from "../hooks/useUploadFile";

export default function UploadScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "UploadScreen">) {
  const { mutateAsync: postArticle } = usePostArticle();
  const { mutate: uploadFile, data: fileResponse, isSuccess } = useUploadFile();

  const [globalError, setGlobalError] = useState("");
  const [image, setImage] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [categoryItems, setCategoryItems] = useState(CategoriesList);

  const form = useForm({
    defaultValues: {
      title: "",
      description: "",
      category: "",
    },
  });

  const fetchImageFromUri = async (uri: string) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  async function handleUpload(values: {
    title: string;
    description: string;
    category: string;
  }) {
    let imageId;
    try {
      const file = await fetchImageFromUri(image);
      const formData = new FormData();

      formData.append("file", file);
      await uploadFile(formData);

      if (isSuccess) {
        imageId = fileResponse?.imageId;
      }
    } catch (error) {
      throw new Error(`Error uploading the image: ${error}`);
    }

    try {
      postArticle({ ...values, image: imageId }).then(() => {
        navigation.pop();
      });
    } catch (error) {
      // await directus.files.deleteOne(imageId);
      let newError = "Server Error";
      if ((error as Error)?.message) {
        newError = (error as Error)?.message;
      }
      setGlobalError(newError);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <Text>{globalError}</Text>
      <View>
        <Controller
          control={form.control}
          rules={{
            required: true,
            maxLength: 80,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <StyledInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                errorMessage={
                  form.formState.errors.title && "This field is required"
                }
                label="Name"
              />
            </>
          )}
          name="title"
        />
        <Controller
          control={form.control}
          rules={{
            required: true,
            maxLength: 120,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <StyledInput
                onBlur={onBlur}
                multiline={true}
                onChangeText={onChange}
                value={value}
                style={{ height: 100 }}
                errorMessage={
                  form.formState.errors.description && "This field is required"
                }
                label="Beschreibung"
              />
            </>
          )}
          name="description"
        />
        <Controller
          control={form.control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <View>
              <Text style={styles.dropDownLabel}>Kategorie</Text>
              <DropDownPicker
                style={styles.dropDown}
                dropDownContainerStyle={styles.dropDownContainer}
                containerStyle={styles.container}
                bottomOffset={-152}
                textStyle={styles.dropDownText}
                showArrowIcon={false}
                showTickIcon={true}
                tickIconContainerStyle={styles.tickIcon}
                placeholder="Wähle eine Kategorie"
                open={listOpen}
                setOpen={() => setListOpen(!listOpen)}
                items={categoryItems}
                setItems={setCategoryItems}
                value={value}
                setValue={(item) => onChange(item(value))}
              />
            </View>
          )}
          name="category"
        />
        {form.formState.errors.category && (
          <Text style={styles.errorText}>This field is required</Text>
        )}
      </View>
      <ImagePicker image={image} setImage={setImage} />
      <StyledButton
        onPress={form.handleSubmit(handleUpload)}
        title={"Hinzufügen"}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  screenContainer: {
    backgroundColor: Colors.default.background,
    padding: 30,
    flex: 1,
  },
  errorText: {
    fontSize: 12,
    margin: 5,
  },
  dropDown: {
    height: 52,
    borderRadius: 15,
    backgroundColor: Colors.default.white,
  },
  dropDownContainer: {
    borderWidth: 0,
    borderRadius: 15,
    width: 310,
  },
  container: {
    paddingHorizontal: 10,
    marginBottom: 32,
    display: "flex",
  },
  dropDownText: { fontSize: 18, padding: 15 },
  dropDownLabel: {
    fontSize: 18,
    fontWeight: "700",
    marginLeft: 10,
    marginBottom: 10,
  },
  tickIcon: {
    position: "absolute",
    top: 16,
    right: 16,
  },
});
